import { ProductType } from 'Component/Product/Product.config';
import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { FormattedMoney } from 'Util/Product/Product.type';

import './ProductPrice.override.style';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    renderPriceWithOrWithoutTax(
        basePrice: Partial<FormattedMoney>,
        _taxPrice: Partial<FormattedMoney>,
        label?: string | ReactElement,
    ): ReactElement {
        return this.renderPrice(basePrice, label);
    }

    renderTierPrice(): ReactElement {
        return (
            null
        );
    }

    renderDefaultPrice(defaultLabel: string | null = null): ReactElement {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label,
        } = this.props;

        if (isSignedIn()) {
            return (
                <>
                    { this.renderOldPrice() }
                    { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                    { this.renderSchema() }
                </>
            );
        }

        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                } = {},
            } = {},
            discountPercentage,
        } = this.props;

        if (originalPriceValue === 0) {
            return null;
        }

        const { itemProp, content } = this.getCurrentPriceSchema();

        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        return (
            <>
                <meta itemProp={ itemProp } content={ String(content) } />
                <PriceSemanticElementName block="ProductPrice" elem="Price">
                    { this.renderPriceBadge(label) }
                    <span
                      itemScope
                      block="ProductPrice"
                      elem="PriceValue"
                    >
                        { originalPriceFormatted }
                    </span>
                </PriceSemanticElementName>
            </>
        );
    }

    render(): ReactElement {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== ProductType.BUNDLE && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
